import { geocodeByPlaceId } from 'react-places-autocomplete'
import { GoogleMapsSelection } from './../Props/GoogleMapsSelection'

const googleMapsAutocompleteParser = async (placeId: string): Promise<GoogleMapsSelection> => {
  const results = await geocodeByPlaceId(placeId).catch((error) => {
    console.error('Error', error)
  })

  const { long_name: address = '' } =
    results[0].address_components.find((c) => c.types.includes('route')) || {}

  const { long_name: city = '' } =
    results[0].address_components.find((c) => c.types.includes('locality')) || {}

  const { long_name: district = '' } =
    results[0].address_components.find((c) => c.types.includes('administrative_area_level_1')) || {}

  const { long_name: postalCode = '' } =
    results[0].address_components.find((c) => c.types.includes('postal_code')) || {}

  const { long_name: country = '' } =
    results[0].address_components.find((c) => c.types.includes('country')) || {}

  return {
    valid: true,
    formatted_address: results[0].formatted_address,
    place_id: placeId,
    lat: results[0].geometry.location.lat(),
    lng: results[0].geometry.location.lng(),
    street: address,
    postal_code: postalCode,
    city: city,
    district: district,
    country: country,
  }
}

export default googleMapsAutocompleteParser
