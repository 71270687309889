import styled from 'styled-components'
import { Input } from 'antd'

export const StyledInput = styled(Input)`
  width: 517px;
  color: #f16749;
  font-size: 16px;
  font-family: Lato;
  font-weight: 400;
  line-height: 24px;
  height: 48px;
  max-width: 100%;
  @media (max-width: 767px) {
    width: 100%;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    width: 400px;
  }

  & input {
    font-size: 16px;
  }
`

export const StyledAutocompleteDropDown = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  padding: 9px 12px;
  background: #fff;
  z-index: 9;
  box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
    0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  &:empty {
    padding: 0;
  }

  div + div {
    margin-top: 10px;
  }

  & .suggestion-item span,
  & .suggestion-item--active span {
    padding: 8px 0;
    display: block;
  }
`
