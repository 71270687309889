import { FaMapMarkerAlt } from 'react-icons/fa'
import PlacesAutocomplete, { Suggestion } from 'react-places-autocomplete'
import { StyledAutocompleteDropDown, StyledInput } from './styles'

interface MapsPlacesAutocompleteProps {
  address: string
  handleChange: (value: string) => void
  handleSelect: (address: string, placeId?: string, suggestion?: Suggestion) => void
  placeholder?: string
  showIcon?: boolean
}

const searchOptions = {
  componentRestrictions: {
    country: 'PT',
  },
}

export const MapsPlacesAutocomplete = ({
  address,
  handleChange,
  handleSelect,
  placeholder,
  showIcon = true,
}: MapsPlacesAutocompleteProps) => {
  return (
    <PlacesAutocomplete
      value={address}
      onChange={handleChange}
      onSelect={handleSelect}
      searchOptions={searchOptions}
    >
      {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
        <div style={{ position: 'relative' }}>
          <StyledInput
            prefix={showIcon && <FaMapMarkerAlt color="#f16749" />}
            {...getInputProps({
              placeholder: placeholder ? placeholder : 'Morada',
              className: 'location-search-input',
            })}
            size="large"
          />
          <StyledAutocompleteDropDown className="autocomplete-dropdown-container">
            {loading && <div>Loading...</div>}
            {suggestions.map((suggestion, key) => {
              const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item'
              // inline style for demonstration purpose
              const style = suggestion.active
                ? { backgroundColor: '#fafafa', cursor: 'pointer', color: '#f16749' }
                : { backgroundColor: '#ffffff', cursor: 'pointer' }
              return (
                <div
                  key={key}
                  {...getSuggestionItemProps(suggestion, {
                    className,
                    style,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              )
            })}
          </StyledAutocompleteDropDown>
        </div>
      )}
    </PlacesAutocomplete>
  )
}
